<template>
    <Documents />
</template>

<script>
import Documents from '@apps/Documents'
import pageMeta from '@/mixins/pageMeta'
export default {
    mixins: [pageMeta],
    components: {
        Documents
    }
}
</script>